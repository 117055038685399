<template>  
  <section>

    <b-overlay :show="loading" rounded="sm">
   
        <input
          :placeholder="$t('LOGIN.INPUT_PASSWORD')"
          class="mt-2 form-control inputTransparent"
          v-model="password" v-on:keyup.enter="loginSubmit"
          autocomplete="current-password"
          type="password"
          name="password"
          required
        />
     
      <button
        v-on:click="loginSubmit"
        class="mt-2 btn btn-fill btn-block btn-primary"
      >
        {{ $t("LOGIN.SUBMIT") }}
      </button>
    
    </b-overlay>

  </section>
</template>
<script>
export default {
  props: ["loginInfo"],
  data() {
    return {
      loading: true,
      password: '',
      errorMessage: ""
    };
  },
  methods: {
    loginSubmit: function() {
      this.$emit("loading",true);
      this.loading = true;
      let self = this;
      let data = {
          email: this.user.email,
          auth_method: this.loginInfo.auth_method,
          auth_type: this.loginInfo.auth_type,
          password: this.password,
          client: 'addin'
        };
      this.$http
        .post(self.user.hostname + "/authenticate", data)
        .then(function(result) {
          self.$store.dispatch('logger/add','authenticated');
          self.$emit('authenticated',result.data.token);
        })
        .catch(function(error) {
          self.loading = false;
          self.$store.dispatch('logger/add','authenticated error');
          self.$emit("loading",false);
          if(error.toString() == "Error: fail_4_4") return;
          if(error.response !== undefined)
          {
            self.$noty.error(self.$t(error.response.data));
          }    
        });
    }
  },
  mounted: function() {
    this.loading = false;
    this.loginInfo.auth_method = "";
  }
};
</script>
<style></style>
